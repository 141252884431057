import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/app/www/theme/src/templates/single-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <br />
    <center>
      <p><img parentName="p" {...{
          "src": "/gifs/excelsmall.gif",
          "alt": null
        }}></img></p>
    </center>
    <br />
    <h3>{`D'un projet à ... beaucoup de projets`}</h3>
    <p>{`La gestion d'un projet est à 3 dimensions: le cout, le délai, le contenu technique. De nombreuses techniques, méthodes et outils sont disponibles pour aider le chef de projet à piloter en tenant le cap de ces trois dimensions externes simultanément. Sans oublier la dimension interne qu'est la gestion des personnes.
Mais un portefeuille de projets n'est pas qu'une sorte de `}<em parentName="p">{`gros projet`}</em>{` qui agglomère tous les projets. En premier lieu le portefeuille de projets pose la question de la consolidation des informations. S'il est clair que la somme des budgets des projets forme le budget du groupe, en est il de même des autres notions comme : le reste à faire, la marge à terminaison, les avenants en cours de négociation, ...`}{` `}{`?`}</p>
    <br />
    <p>{`Le portefeuille de projets fait apparaitre un problème qui n'existe pas sous cette forme au niveau du projet individuel: l'allocation de ressources. Il ne s'agit plus de faire passer un collaborateur d'une tâche terminée à une autre à démarrer, officialisé par un simple commentaire dans le compte rendu de la réunion projet hebdomadaire. Il s'agit de quelque chose de bien plus lourd de conséquences: faire sortir un collaborateur d'un projet (d'un budget) pour le faire rentrer sur un autre projet (budget). La comptabilité analytique est à juste titre très pointilleuse. Parmi le travail à faire: arbitrer l'allocation des ressources entre projets, lisser les sous- et surcharges entre projets, prévoir les embauches et sous-traitances à réaliser sur les prochains mois, ...`}</p>
    <h3>{`Le problème du Responsable de plusieurs projets`}</h3>
    <p>{`Dans la plupart des sociétés qui gèrent des projets, pour elle même ou pour des clients, le moyen institutionnalisé d'obtenir de l'information consolidée sur un périmètre de projets est de faire de la ... comptabilité. On transforme tout en unité monétaire € et tout devient aisément consolidable selon la recette suivante:  `}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Collecter les "feuilles de temps" individuelles: qui a passé combien de temps sur le projet/lot/tâche le mois/semaine dernier, et le reste à faire par projet/lot/tâches.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Pour chaque projet, multiplier les couts de revient individuels par les heures passées, ajouter les achats réalisés et compléter par le cout restant à passer déduits des restes à faire de chaque tâche. `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Mettre en regard des données contractuelles: le montant de la commande, le facturé à date, ...`}</p>
        <br />
      </li>
    </ul>
    <p>{`Il est alors possible de calculer la marge actuelle et à terminaison qui seront consolidées par groupe de projets et remontées au prochain comité de direction mensuel. Cela permet de faire le tri entre projets qui gagnent ou perdent de l'argent, aujourd'hui et demain. `}</p>
    <br />
    <p>{`Cette boucle n'est généralement que mensuelle et l'information n'arrive au mieux qu'une semaine après la clôture du mois. Suffisant pour un contrôle macroscopique, mais insuffisant pour aider à prendre les décisions de tous les jours. `}<em parentName="p">{`L'information financière est trop globale et trop peu fréquente.`}</em>{`
Dans une organisation qui accueille des projets hétérogènes: domaine d'application, taille, contexte technique, méthodes de gestion, rythme de mise à jour, granularité des tâches, du forfait pur et dur au projet agile, du `}<em parentName="p">{`one shot`}</em>{` aux mini forfaits (unités d'`}{`œ`}{`uvres) récurrents, ...  Il est illusoire de chercher à consolider les plannings de tous les projets en un seul, au niveau le plus fin tel que nécessaire aux différents chefs de projets: la tâche, la ressource et la journée. `}<em parentName="p">{`Les planning projet sont trop détaillés et trop hétérogènes pour être consolidés`}</em>{`.`}</p>
    <h3>{`Une solution: suivre les gabarits des projets`}</h3>
    <p>{`La solution mise en place consiste à définir un référentiel intermédiaire entre le financier et le planning. La granularité peut être ajustée, en fonction de la dynamique des projets, du plus global: les projets, à des niveaux plus détaillés: phases, lots ou tâches. Chaque élément est emballé dans des `}<em parentName="p">{`gabarits`}</em>{` de la forme:`}</p>
    <ul>
      <li parentName="ul">{`Date de début`}</li>
      <li parentName="ul">{`Date de fin`}</li>
      <li parentName="ul">{`Montant en € ou en hXj`}</li>
    </ul>
    <br />
    <p>{`Chaque projet ou groupe de projets apparait à son responsable comme un simple tableau Excel triable, filtrable.`}</p>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "13.496932515337424%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAIAAAAcOLh5AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAr0lEQVQI1xXIUQqCMAAAUO9/hIgiPwrncjpRs6nlbNNpizKioHQzhK5Rfb5nuFynUodisHKF2IDL3i56QLtM/qZDTGGuYNEnR+2XumhH59CncgiE9kplzHCzEcoi7cRrpl4DsjvIO0gVkW9r/4S0c5kC+SuutRlKlF0WwQmmLUhvc782lnhHz0NYXGDEQUBR0jhEVPcPu452xP6MK0QqUj1WOLcj7sTleiu2/GY6yRdQZohmOOEF7AAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "PetitTableau",
          "title": "PetitTableau",
          "src": "/static/90e71367fde9b22c156e202838f7ed6a/a6d36/PetitTableau.png",
          "srcSet": ["/static/90e71367fde9b22c156e202838f7ed6a/222b7/PetitTableau.png 163w", "/static/90e71367fde9b22c156e202838f7ed6a/ff46a/PetitTableau.png 325w", "/static/90e71367fde9b22c156e202838f7ed6a/a6d36/PetitTableau.png 650w", "/static/90e71367fde9b22c156e202838f7ed6a/f8067/PetitTableau.png 726w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span>{` `}</p>
    <br />
    <p>{`Les champs peuvent être mis à jour: décalage d'une date, signature d'un avenant. Mais au lieu de rester dans un fichier Excel la mise à jour s'effectue dans un cube `}<a parentName="p" {...{
        "href": "https://fr.wikipedia.org/wiki/Traitement_analytique_en_ligne"
      }}>{`OLAP`}</a>{` qui depuis un site éventuellement distant donne accès à ces données aux différents chefs de projets en fonction de leur droits (lecture / écriture) au travers d'Excel. Le cube consolide les différents projets et il devient possible de définir des tableaux de bord visuels:`}</p>
    <br />
    <p>{`Plusieurs graphiques peuvent être déduits dynamiquement de ce simple tableau:`}</p>
    <ul>
      <li parentName="ul">{`la barre colorée dans la colonne des montants qui représente la part relative de chaque élément,`}</li>
      <li parentName="ul">{`le `}<strong parentName="li">{`GanttChart`}</strong>{`, qui matérialise: le début et la fin de chaque projet, un code couleur est attribué à chaque client,`}</li>
      <li parentName="ul">{`le `}<strong parentName="li">{`LoadChart`}</strong>{`, qui fait la somme des intensités de chaque ligne et qui synthétise l'évolution de la charge cumulée.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "32.515337423312886%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABiElEQVQY0xXQW1ObUBQFYP5w/4nTV8c8VK1mbB+qaYKSCwFCkNBwVwhjjpBGM7kZrucAIcShpzPr4Vt7vy0iTFI/zmKURzDzI5SgPIRZmOCah0kWob0fp36UYv9Hku0iFMA9vszeVgTaf6K8DNMyzo7YATrArMTvABYROoSwQPkxLz6jtMz3ZVGU2DFEfxeLLsMQp3fqT9o+uRnh1Jr6yY/x+b3xnQbnvddLGtT74JIyryi91lBP75+/XNs1yrJH0nzYjW2JoHh9qLkdQRcMT3aWpvuhgY08eZee58brhzWP6JFFskr/Ub/hX8760xanAN3eerP16kA0B2ZfAZRgDc034eldnu5wRGs5mqwxVOC3Bes3o7Z5oz6YfmNBoyPiIWIIhfGYeOBNXnNbrCpaiz/OSp5uVbDrPFo9yZEmS831u6LdZBRa0C/Yl689j6SlbYCq6qjNOKLJmZzmNRi1xRkNRmuLjuRsyIFx25NvaeVBsMmBeUcrJKfV2ckF5/5qi5sAVlW1tr1/GRld9cUwaJEAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "TableauGantt",
          "title": "TableauGantt",
          "src": "/static/44ddc54f14b1408a52d581ff2240488d/a6d36/TableauGantt.png",
          "srcSet": ["/static/44ddc54f14b1408a52d581ff2240488d/222b7/TableauGantt.png 163w", "/static/44ddc54f14b1408a52d581ff2240488d/ff46a/TableauGantt.png 325w", "/static/44ddc54f14b1408a52d581ff2240488d/a6d36/TableauGantt.png 650w", "/static/44ddc54f14b1408a52d581ff2240488d/c1328/TableauGantt.png 866w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span>{` `}</p>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "42.94478527607362%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsSAAALEgHS3X78AAAB5klEQVQoz42R6W7aQBSF/f6P0D+RKrVq1S1VuqhKItIiQgoGDDaLATvGxsbYxmaLWQJ8HVv0Z6WOdHVmuffcb2Yk23bwPA/TNNnudmTjdDr9M/6ep2nKdrulrbWJoojn/Z71eo2UxDPmScL/juPhkOtmk7I/A0wDH2fs5HNpvkpZiFinWzb7Yx7rdCf0QLLaIDd7DO0pnaGNHy2oyHX8MGab5e6OYi+hdFeiUaoI0jnSVdnksqjzudiloIYUtIgv932u62NumlNevP/Juzudl9/rfK3YvP5W4e1Niw+3Cj+qIz4WO1xcXXNR+IXsBEg9w+Gh1qKm9lB7JlWlQ7MzpNU1MJ0pmm5i2n6u9mTGYDShWJbpmzbhPMWwJ5RffUJ5c0lg2EiZidzsYvsJzjThd10VRR7T5InJbJWbZyZN0cD2Y3F9n/uqghsuCJIUd7ZmtowJ3R5x+IRUFWY1Vc+JsiIvWhLMN0TLndAUpT1gYE1yHU2E4cinVFHyPF+Y6ZaHLsgc8bGLzQGprvXPRqucQDkTjYN5vpeth2KdqeXNMMQzlOUmXrjMGwZxyvjWY2w5wnAv3vDRpdLoCMo+cqsnktV83jVdTDeioQ3QHz3qQi1BOBhlVxaGgs4VDY1xhFZSsLomcfrMH5h2kWt419kSAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "PortefeuilleProjets",
          "title": "PortefeuilleProjets",
          "src": "/static/9027c3c9a7f54adf94a3fbbf9cde810b/a6d36/PortefeuilleProjets.png",
          "srcSet": ["/static/9027c3c9a7f54adf94a3fbbf9cde810b/222b7/PortefeuilleProjets.png 163w", "/static/9027c3c9a7f54adf94a3fbbf9cde810b/ff46a/PortefeuilleProjets.png 325w", "/static/9027c3c9a7f54adf94a3fbbf9cde810b/a6d36/PortefeuilleProjets.png 650w", "/static/9027c3c9a7f54adf94a3fbbf9cde810b/e548f/PortefeuilleProjets.png 975w", "/static/9027c3c9a7f54adf94a3fbbf9cde810b/3c492/PortefeuilleProjets.png 1300w", "/static/9027c3c9a7f54adf94a3fbbf9cde810b/71e8d/PortefeuilleProjets.png 1304w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <p>{`Le responsable peut s'appuyer sur ces synthèses pour suivre l'activité, le respect des échéances contractuelles, l'avancement des travaux et même étudier les conséquences sur le plan de charge des propositions de projets en cours de négociation. `}</p>
    <br />
    <p>{`Et les caractéristiques projets suivantes`}</p>
    <br />
    <center>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}><em parentName="th">{`Projet`}</em></th>
            <th parentName="tr" {...{
              "align": null
            }}><em parentName="th">{`Montant`}</em></th>
            <th parentName="tr" {...{
              "align": null
            }}><em parentName="th">{`Début`}</em></th>
            <th parentName="tr" {...{
              "align": null
            }}><em parentName="th">{`Fin`}</em></th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Mise en place du site web`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`100.000€`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`2010-01-01`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`2010-12-31`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Développement de la plateforme`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`120.000€`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`2010-01-01`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`2010-12-31`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Test de la version 1.2`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{` `}{` `}{`60.000€`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`2010-07-01`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`2010-11-30`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Refonte de l'interface`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{` `}{` `}{`90.000€`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`2010-01-01`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`2010-04-01`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Odyssey`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`300.000€`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`2009-12-01`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`2010-11-01`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Label`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{` `}{` `}{`80.000€`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`2010-07-01`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`2010-11-30`}</td>
          </tr>
        </tbody>
      </table>
    </center>
    <br />
    <p>{`La dynamique des projets gérés par `}<em parentName="p">{`unités d'`}{`œ`}{`uvre`}</em>{` peut également venir compliquer la gestion. Pour ces projets, le planning est constamment en mouvement car il s'agit de réaliser un `}<em parentName="p">{`flux de travaux`}</em>{` en provenance du client. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      